<template>
  <vc-donut
    :sections="setSections"
    :start-angle="20"
    :total="ifSections"
    has-legend
    legend-placement="right"
    :size="160"
    unit="px"
    class="caption"
  >
    <span class="number-app-active caption">{{ getSections }}</span>
    <br>
    Resumen
    <br>
    Candidatos
  </vc-donut>
</template>

<script>
import LoadCard from "../../../../mixins/LoadCard";
export default {
  mixins: [LoadCard],
  props: ['dataDonut'],
  data: () => ({
    sections: [
      { label: "Citas programadas", value: 0, color: '#FFB149' },
      { label: "Contratados", value: 0, color: '#2699FB' },
      { label: "Rechazados", value: 0, color: '#A3A1FB' },
      { label: "Otros estatus", value: 0, color: '#16C4C0' },
    ]
  }),
  computed: {
    getSections() {
      return this.dataDonut.totalApp + this.dataDonut.totalEng + this.dataDonut.totalOth + this.dataDonut.totalRej;
    },
    ifSections(){
      if(this.dataDonut.totalApp + this.dataDonut.totalEng + this.dataDonut.totalOth + this.dataDonut.totalRej<=100){
        return 100
      }else{
        return this.dataDonut.totalApp + this.dataDonut.totalEng + this.dataDonut.totalOth + this.dataDonut.totalRej
      }
    },
    setSections() {
      return [
        { label: "Citas programadas", value: this.dataDonut.totalApp, color: '#FFB149' },
        { label: "Contratados", value: this.dataDonut.totalEng, color: '#2699FB' },
        { label: "Rechazados", value: this.dataDonut.totalRej, color: '#A3A1FB' },
        { label: "Otros estatus", value: this.dataDonut.totalOth, color: '#16C4C0' },
      ]
    }
  }
};
</script>

<style scoped>
.donut {
  width: 7rem;
  height: 7rem;
  border-radius: 100%;
  border: 15px solid #a3a1fb;
}

.number-app-active {
  font-size: 30px;
  font-weight: 400;
  color: rgb(140, 155, 251);
}
</style>
