<template>
    <v-card class="pa-5 pb-10" width="100%">
      <table-aprove :vacancies="this.vacancies"></table-aprove>
    </v-card>
</template>

<script>
import TableAprove from './tables/TableAprove.vue'
    export default {
        name: "LeftDash",
        components: {TableAprove},
        props: ["vacancies"]
    }
</script>
