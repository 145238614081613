var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{attrs:{"width":"100%","height":"100%","type":"list-item, image","loading":_vm.loading}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"id":"table-vacancies-zone","headers":_vm.headers,"items":_vm.getData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","sort-by":"number"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('p',{staticClass:"text-body-1 ma-0 font-weight-bold"},[_vm._v("Vacantes por zona")])])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"caption ma-0 pa-0"},[_vm._v(_vm._s(item.name))])]}},{key:"item.vacancies",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"caption ma-0 pa-0"},[_vm._v(_vm._s(item.vacancies))])]}},{key:"item.candidates",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"caption ma-0 pa-0"},[_vm._v(_vm._s(item.candidates))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"caption ma-0 pa-0"},[_vm._v(_vm._s(item.status))])]}}],null,true)}),_c('div',{staticClass:"text-center pt-5"},[_c('v-pagination',{attrs:{"circle":"","small":"","color":"#8739EB","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }