import { render, staticRenderFns } from "./TableAprove.vue?vue&type=template&id=2571bdd1&"
import script from "./TableAprove.vue?vue&type=script&lang=js&"
export * from "./TableAprove.vue?vue&type=script&lang=js&"
import style0 from "./TableAprove.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VDataTable,VPagination,VSkeletonLoader,VToolbar,VToolbarTitle})
