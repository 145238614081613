<template>
  <div>
    <v-skeleton-loader
      width="100%"
      height="100%"
      type="list-item, image"
      :loading="loading"
    >
      <v-data-table
        id="table-vacancies-zone"
        :headers="headers"
        :items="getData"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
        sort-by="number"
        class="elevation-0"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <p class="text-body-1 ma-0 font-weight-bold">Vacantes por zona</p>
            </v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:[`item.name`]="{ item }">
            <p class="caption ma-0 pa-0">{{ item.name }}</p>
        </template>
        <template v-slot:[`item.vacancies`]="{ item }">
            <p class="caption ma-0 pa-0">{{ item.vacancies }}</p>
        </template>
        <template v-slot:[`item.candidates`]="{ item }">
            <p class="caption ma-0 pa-0">{{ item.candidates }}</p>
        </template>
        <template v-slot:[`item.status`]="{ item }">
            <p class="caption ma-0 pa-0">{{ item.status }}</p>
        </template>
      </v-data-table>
      <div class="text-center pt-5">
        <v-pagination
          circle
          small
          color="#8739EB"
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
    </v-skeleton-loader>
  </div>
</template>

<script>
import LoadCard from "../../../../mixins/LoadCard";

export default {
  name: "TableAprove",
  mixins: [LoadCard],
  props: ["vacancies"],
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 3,
    headers: [
      { text: "Zona", value: "name", align: "start" },
      { text: "Vacantes ", value: "vacancies", align: "start" },
      { text: "Candidatos", value: "candidates", align: "start" },
      { text: "Estatus", value: "status", align: "start" }
    ],
  }),
  computed: {
    getData() {
      let dataAux = [];
      for (let i = 0; i < this.vacancies.length; i++) {
        dataAux.push(
          {
            name: this.vacancies[i].zoneName,
            candidates: this.vacancies[i].totalApp + this.vacancies[i].totalEng + this.vacancies[i].totalOth + this.vacancies[i].totalRej,
            vacancies: this.vacancies[i].totalVac,
            status: "Activa"
          }
        );
      }
      return dataAux;
    }
  }
};
</script>
<style>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #7570f8 !important;
}

.v-data-table .v-data-table-header tr th {
  background-color: rgba(216, 219, 247, 0.2) !important;
}
</style>