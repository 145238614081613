<template>
  <v-card class="pb-12" width="100%" >
    <CandidatesHistory :dataChart="getChartInfo" />
  </v-card>
</template>

<script>
import CandidatesHistory from "./charts/Candidates.vue";

export default {
  name: "RightDash",
  components: { CandidatesHistory },
  props: ['candidate', 'hired'],
  computed: {
    getChartInfo() {
      return {
        type: "bar",
        data: {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
          ],
          datasets: [
            {
              label: "Candidatos",
              data: this.candidate,
              backgroundColor: "#A4A1FB",
              borderColor: "#A4A1FB",
              borderRadius: 5,
            },
            {
              label: "Contratado",
              data: this.hired,
              backgroundColor: "#D2D1F7",
              borderColor: "#D2D1F7",
              borderRadius: 5,
            },
          ]
        },
        options: {
          responsive: true,
          lineTension: 1,
          barThickness: 7,
          scales: {
            x: {
              display: false,
            },
            y: {
              grace: '5%'
            },
          },
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                usePointStyle: true,
                padding: 32,
              },
            },
          },
        }
      }
    }
  }
};
</script>
