<template>
  <v-container fluid grid-list class="pt-0 pb-1">
    <v-layout wrap column>
      <v-card elevation="0" class="mb-4">
        <v-card-subtitle class="black--text font-weight-bold pa-2 pb-0 ma-2 mr-6">
          Historial de candidatos
        </v-card-subtitle>
        <v-divider></v-divider>
        <div class="d-flex align-center justify-center pa-6">
          <canvas id="candidatesChartsId"></canvas>
        </div>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "CandidatesHistory",
  props: ["dataChart"],
  data: () => ({
    dataElement: null
  }),
  watch: {
    dataChart() {
      this.getInfo();
    }
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      if (this.dataElement) {
        this.dataElement.destroy();
      }
      const ctx = document.getElementById("candidatesChartsId");
      this.dataElement = new Chart(ctx, this.dataChart);
    }
  }
};
</script>

<style></style>
