<template>
  <div style="background-color: #F9F9F9" class="container-dash ma-0 pa-0 ml-5 pb-16">
    <v-card style="background-color: #F9F9F9" width="90vw" elevation="0">
      <v-container fluid grid-list class="pa-0 ma-0">
        <v-layout style="margin-top: 20px" wrap column d-flex justify-center>
          <v-flex xs12 sm12 md12 lg12 class="pa-0 ma-0">
            <top
              :zonesOptions="this.zonesOptions"
              :appCurrentMonth="this.zoneCurrent.appCurrentMonth"
              :vacCurrentMonth="this.zoneCurrent.vacCurrentMonth"
              :engCurrentMonth="this.zoneCurrent.engCurrentMonth"
              :zoneData="this.zoneCurrent"
              :onResponseTop="getFilterData"
            >
            </top>
          </v-flex>
          <v-layout wrap row d-flex justify-center class="mt-5 ma-0 pa-0">
            <v-flex class="left mr-5">
              <left :vacancies="this.zoneFilter" />
            </v-flex>
            <v-flex class="right">
              <right :hired="this.zoneCurrent.hiredForMonths" :candidate="this.zoneCurrent.candidatesForMonths" />
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import top from "../../components/Dashboard/top/TopDashManager.vue";
import left from "../../components/Dashboard/left/LeftDashManager.vue";
import right from "../../components/Dashboard/right/RightDashManager.vue";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
  components: {
    top,
    left,
    right,
  },
  data: () => ({
    zonesOptions: [],
    zonesData: [],
    zoneFilter: [],
    zoneCurrent: {
      zoneID: "",
      zoneName: "",
      vacCurrentMonth: 0,
      appCurrentMonth: 0,
      engCurrentMonth: 0,
      totalVac: 0,
      totalApp: 0,
      totalEng: 0,
      totalRej: 0,
      totalOth: 0,
      candidatesForMonths: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      hiredForMonths: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  }),
  methods: {
    ...mapActions(["requestsAllZones", "requestAllVacancies", "requestsAllCandidatesWP"]),

    getFilterData(option) {
      const objectFound = this.zonesData.find((opt, i) => opt.zoneName === option);
      let objectFoundAux = [];

      if (objectFound && option !== "General") {
        objectFoundAux = this.zonesData.filter((opt, i) => opt.zoneName === option);
      } else if (objectFound && option === "General") {
        objectFoundAux = this.zonesData.filter((opt, i) => opt.zoneName !== option);
      }

      this.zoneCurrent = objectFound;
      this.zoneFilter = objectFoundAux;
    },
    getMonthCandidate(candidateDate) {
      return new Date(candidateDate.candidate_created_at).getMonth()+1;
    },
    isValidPeriod(dateValidStart, dateValidEnd, type) {
      const date = new Date();
      if (type === "vacancy") {
        if (date >= dateValidStart && date <= dateValidEnd) {
          return true;
        }
        return false;
      } else {
        const primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
        const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        if (dateValidStart >= primerDia && dateValidStart <= ultimoDia) {
          return true;
        }
        return false;
      }
    },
    getRequestsData() {
      if (this.companyZones.length > 0) {
        this.zonesOptions.push("General");
        let objZoneAll = {
          zoneID: "",
          zoneName: "General",
          vacCurrentMonth: 0,
          appCurrentMonth: 0,
          engCurrentMonth: 0,
          totalVac: 0,
          totalApp: 0,
          totalEng: 0,
          totalRej: 0,
          totalOth: 0,
          candidatesForMonths: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          hiredForMonths: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        for (let iz = 0; iz < this.companyZones.length; iz++) {
          let objZone = {
            zoneID: this.companyZones[iz].zone_id,
            zoneName: this.companyZones[iz].company_zone_name,
            vacCurrentMonth: 0,
            appCurrentMonth: 0,
            engCurrentMonth: 0,
            totalVac: 0,
            totalApp: 0,
            totalEng: 0,
            totalRej: 0,
            totalOth: 0,
            candidatesForMonths: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            hiredForMonths: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          };

          if (this.companyZones[iz].zone_is_active && !this.companyZones[iz].zone_is_archive) {
            this.zonesOptions.push(`${this.companyZones[iz].company_zone_name}`);

            // Vacantes
            this.requestAllVacancies(this.companyZones[iz].zone_id).then((resVacancies) => {
              if (resVacancies.data.length > 0 && resVacancies.data !== "No vacancies avaliable.") {
                // Total de vacantes
                objZone.totalVac += Number(resVacancies.data.length);
                objZoneAll.totalVac += Number(resVacancies.data.length);
                
                for (let iv = 0; iv < resVacancies.data.length; iv++) {
                  // Vacantes activas
                  if (this.isValidPeriod(new Date(resVacancies.data[iv].vacancy_starts_at), new Date(resVacancies.data[iv].vacancy_expires_at), "vacancy")) {
                    objZone.vacCurrentMonth += 1;
                    objZoneAll.vacCurrentMonth += 1;
                  }

                  this.requestsAllCandidatesWP({vac: resVacancies.data[iv].vacancy_id, zon: this.companyZones[iz].zone_id}).then((resCandidates) => {
                    if (resCandidates !== "No candidates found!") {

                      // Appointments and engagement (actives...)
                      for (let ic = 0; ic < resCandidates.length; ic++) {
                        if (resCandidates[ic].candidate_is_active) {
                          let indexMonth = this.getMonthCandidate(resCandidates[ic]);
                          objZone.candidatesForMonths[indexMonth] += 1;
                          objZoneAll.candidatesForMonths[indexMonth] += 1;

                          if (resCandidates[ic].candidate_status === 2) {
                            objZone.totalApp += 1;
                            objZoneAll.totalApp += 1;
                            if (this.isValidPeriod(new Date(resCandidates[ic].candidate_created_at), new Date(), "candidate")) {
                              objZone.appCurrentMonth += 1;
                              objZoneAll.appCurrentMonth += 1;
                            }
                          } else if (resCandidates[ic].candidate_status === 5) {
                            objZone.hiredForMonths[indexMonth] += 1;
                            objZoneAll.hiredForMonths[indexMonth] += 1;
                            objZone.totalEng += 1;
                            objZoneAll.totalEng += 1;
                            if (this.isValidPeriod(new Date(resCandidates[ic].candidate_created_at), new Date(), "candidate")) {
                              objZone.engCurrentMonth += 1;
                              objZoneAll.engCurrentMonth += 1;
                            }
                          } else if (resCandidates[ic].candidate_status === 1) {
                            objZone.totalRej += 1;
                            objZoneAll.totalRej += 1;
                          } else {
                            objZone.totalOth += 1;
                            objZoneAll.totalOth += 1;
                          }
                        }
                      }
                    }
                  });
                }
              }
            });

            this.zonesData.push(objZone);
            this.zoneFilter.push(objZone);
          }
        }
        this.zonesData.unshift(objZoneAll);
        this.zoneCurrent = objZoneAll;
      }
    }
  },
  computed: {
    ...mapGetters(["getAllCandidates", "userType"]),
    ...mapState({
      companyZones: (state) => state.auth.companyZones,
      currentCandidates: (state) => state.candidates.candidates,
      userPermits: (state) => state.auth.user.user_type
    })
  },
  watch: {
    companyZones() {
      this.getRequestsData();
    }
  },
  beforeCreate() {
    this.$store.dispatch("requestsAllZones");
  },
  // beforeMount() {
  //   this.getRequestsData();
  // }
};
</script>

<style>
.container-dash {
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}
.left {
  width: 35vw !important;
}
.right {
  width: 15vw;
}
</style>
