<template>
  <v-card
    elevation="0"
    color="#F7F8FA"
    class="d-flex align-center justify-center">
    <v-skeleton-loader
      height="22vh"
      width="30vw"
      type="list-item-three-line"
      :loading="loading"
    >
      <div class="d-flex flex-column justify-center pa-2" style="width: 13vw">
        <div class="d-flex justify-space-between" style="width: 100%">
          <p class="text-caption pa-0 ma-0" style="color: #bcbdc2">
            Mes pasado
            <span class="font-weight-bold ml-2" style="color: #2ccc70"
              >+0%</span
            >
          </p>
          <v-icon small color="#52C800">mdi-account-circle</v-icon>
        </div>
        <div class="d-flex flex-column align-start mt-3">
          <p class="ma-0 text-caption">Contrataciones</p>
          <h2 class="ma-0">{{ this.data }}</h2>
          <p class="text-caption">nuevas este mes</p>
        </div>
      </div>
    </v-skeleton-loader>
  </v-card>
</template>

</template>

<script>
import LoadCard from '../../../../mixins/LoadCard'
export default {
  name: "topFour",
  mixins: [LoadCard],
  props: ['data']
}
</script>