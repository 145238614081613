<template>
  <v-card class="pb-5" elevation="1" width="100vw">
    <v-card-title class="d-flex align-center pt-8 pb-6">
      <v-icon color="#A3AEFF" class="ml-6 mr-4">mdi-home-circle</v-icon>
      <p class="text-body-1 ma-0 font-weight-bold">Dashboard</p>
      <v-icon class="mr-2 ml-10" small color="#6F50FF"
        >mdi-help-circle-outline</v-icon
      >
      <p class="text-caption ma-0">
        Gestiona las vacantes y monitorea el desempeño de los colaboradores en
        tiempo real
      </p>
      <div style="margin: auto">
        <v-select class="selectTop" :items="this.zonesOptions" label="Zonas" @change="onResponseTop($event)">
          <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-map-marker-outline
                        </v-icon>
                        <span class="caption ml-2">Zona</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <p class="caption">{{ item }}</p>
                          <v-divider></v-divider>
                        </v-list-item-content>
                      </template>
        
        </v-select>
      </div>
    </v-card-title>

    <v-container fluid grid-list-md>
      <v-layout row wrap d-flex justify-center>
        <v-flex d-flex xs12 sm12 md5 child-flex>
          <active :dataDonut="this.zoneData"></active>
        </v-flex>
        <v-flex d-flex xs12 sm12 md2 child-flex>
          <one-top :data="this.vacCurrentMonth"></one-top>
        </v-flex>
        <v-flex d-flex xs12 sm12 md2 child-flex>
          <three-top :data="this.appCurrentMonth"></three-top>
        </v-flex>
        <v-flex d-flex xs12 sm12 md2 child-flex>
          <four-top :data="this.engCurrentMonth"></four-top>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import Active from "./charts/Active.vue";
import OneTop from "./cards/OneTop.vue";
import ThreeTop from "./cards/ThreeTop.vue";
import FourTop from "./cards/FourTop.vue";

export default {
  components: {
    Active,
    OneTop,
    ThreeTop,
    FourTop,
  },
  props: ['zonesOptions', 'vacCurrentMonth', 'appCurrentMonth', 'engCurrentMonth', 'zoneData', 'onResponseTop'],
};
</script>

<style>
  .selectTop label { font-size: 12px; }
</style>
